import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { printf as sprintf } from 'fast-printf';

import { useHistory } from './useHistory';
import { useMedia } from './useMedia';
import { Duration, TTS } from 'cte-video-studio';
import { TTS_STATUS_PROCESSING } from '../../constants/index.js';
import AudioIsolationService from '@/js/videos/application/services/AudioIsolationService.js';
import { useNotification } from '@/js/composables/useNotification.js';

export function useVoiceOver(useInSequence, sequenceId, store = null) {
    if (!store) {
        store = useStore();
    }

    const { t } = useI18n();
    const { saveHistoryStep, ignoreHistoryStep } = useHistory(store);
    const { success, error } = useNotification('toast', false);
    const audioIsolationService = new AudioIsolationService();

    const storeModulePath = computed(() => {
        return useInSequence.value ? 'sequences/' + sequenceId.value + '/tts' : 'settings/tts';
    });

    const voiceOverState = computed(() => {
        return useInSequence.value ? store.state.sequences[sequenceId.value]?.tts : store.state.settings.tts;
    });

    const voiceOverMediaState = computed(() => store.getters[storeModulePath.value + '/media']);

    const voiceOverCategory = computed({
        get() {
            return voiceOverState.value.category;
        },
        set(value) {
            saveHistoryStep(() => {
                store.commit(storeModulePath.value + '/setCategory', value);
            });
        }
    });

    const isAudioCategory = computed(() => voiceOverCategory.value === TTS.AUDIO_CATEGORY);
    const isVoiceCategory = computed(() => voiceOverCategory.value === TTS.VOICE_CATEGORY);
    const isRecordingCategory = computed(() => voiceOverCategory.value === TTS.RECORDING_CATEGORY);
    const isNoneCategory = computed(() => voiceOverCategory.value === TTS.NONE_CATEGORY);

    const isProcessing = computed(() => {
        return voiceOverState.value.voice.status === TTS_STATUS_PROCESSING;
    });

    const { mediaModel: voiceOverMediaAudioModel } = useMedia(storeModulePath.value + '/audio', store);
    const { mediaModel: voiceOverRecordingModel } = useMedia(storeModulePath.value + '/recording', store);
    const { deleteSource: voiceOverDeleteVoiceSource } = useMedia(storeModulePath.value + '/voice', store);

    const showTtsEditor = () => {
        store.dispatch('ui/showTTSEditor', {
            seqId: useInSequence.value ? sequenceId.value : null,
            useInSequence: useInSequence.value
        });
    };

    const timerange = computed({
        get: () => {
            const range = { start: 0, end: -1 };
            range.start = voiceOverMediaState.value?.timerange.start || 0;
            range.end = voiceOverMediaState.value?.timerange.end || -1;

            return range;
        },
        set: ({ start, end }) => {
            if (start !== timerange.value.start || end !== timerange.value.end) {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setTimerange', { start, end });
                });
            }
        }
    });

    const timerangeSegments = computed({
        get: () => {
            return voiceOverMediaState.value.timerangeSegments || [];
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setTimerangeSegments', value);
            });
        }
    });

    const playbackRate = computed({
        get: () => {
            return store.getters[storeModulePath.value + '/playbackRate'] || Duration.PLAY_BACK_RATE_DEFAULT;
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setPlaybackRate', value);
            });
        }
    });

    const voiceOverCaptions = computed({
        get: () => {
            return voiceOverMediaState.value.captions || [];
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.dispatch(storeModulePath.value + '/setCaptions', value);
            });
        }
    });

    const voiceOverStart = computed({
        get: () => voiceOverMediaState.value.start,
        set: (value) => {
            saveHistoryStep(() => store.dispatch(storeModulePath.value + '/setStart', value));
        }
    });

    // only works on Custom file (not AI voice)
    const isImproving = ref(false);
    const audioImproved = computed({
        get: () =>
            isAudioCategory.value ? voiceOverState.value.audio.improved : voiceOverState.value.recording.improved,
        set: (value) => {
            const module = isAudioCategory.value ? 'audio' : 'recording';

            const oldValue = voiceOverState.value[module].improved;
            ignoreHistoryStep(() => store.commit(`${storeModulePath.value}/${module}/setImproved`, value));

            isImproving.value = true;

            (async () => {
                const sequenceNumber = useInSequence.value
                    ? store.getters['sequences/' + sequenceId.value + '/index'] + 1
                    : null;
                const variationUuid = voiceOverState.value[module].src__id;
                const response = value
                    ? await audioIsolationService.isolate(variationUuid)
                    : await audioIsolationService.cancel(variationUuid);

                const toastData = useInSequence.value
                    ? { type: 'sequence', parameters: [response.error || t('Unknown error')] }
                    : {
                          type: 'settings',
                          parameters: [response.error || t('Unknown error')]
                      };

                if (response.hasOwnProperty('error')) {
                    error(sprintf(t(`audio-isolation.voice-over-${toastData.type}-error`), ...toastData.parameters));
                    ignoreHistoryStep(() => store.commit(`${storeModulePath.value}/${module}/setImproved`, oldValue));
                } else {
                    saveHistoryStep(() => {
                        store.dispatch(`${storeModulePath.value}/${module}/update`, {
                            src__id: response.variation_uuid || '',
                            src: response.variation_uuid
                                ? store.getters['ui/mediaPreviewUrl'](response.variation_uuid)
                                : '',
                            improved: value
                        });
                    });

                    // notification only when audio has been improved
                    if (value) success(t(`audio-isolation.voice-over-${toastData.type}-success`));
                }

                isImproving.value = false;
            })();
        }
    });

    const categoryIcons = {
        [TTS.AUDIO_CATEGORY]: 'fa-regular fa-volume',
        [TTS.VOICE_CATEGORY]: 'fa-regular fa-microphone',
        [TTS.RECORDING_CATEGORY]: 'fa-regular fa-circle-dot'
    };

    return {
        storeModulePath,
        voiceOverState,
        voiceOverMediaState,
        voiceOverCategory,
        isNoneCategory,
        isAudioCategory,
        isVoiceCategory,
        isRecordingCategory,
        isProcessing,
        voiceOverMediaAudioModel,
        voiceOverRecordingModel,
        voiceOverDeleteVoiceSource,
        showTtsEditor,
        timerange,
        timerangeSegments,
        playbackRate,
        voiceOverCaptions,
        voiceOverStart,
        audioImproved,
        isImproving,
        categoryIcons
    };
}
