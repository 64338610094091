import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FetchBrandsApiService {
    constructor(store) {
        this.store = store;
    }

    fetchBrands(options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchBrands'
        });
    }

    fetchMinimalBrandsForOrganization(organizationId, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${API_URL}/api/organizations/${organizationId}/brands-minimal?${queryParams.toString()}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchMinimalBrandsForOrganization'
        });
    }
    fetchBrandResources(brandId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}/resources`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchBrandResources'
        });
    }

    fetchBrandById(brandId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/${brandId}`);
            }
        });
    }

    fetchBrandColors(folderId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/folders/${folderId}/brand-colors`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchBrandColors'
        });
    }

    fetchMasters(options = {}) {
        const { page = 1, search = '', count = 15 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            per_page: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${API_URL}/api/brands/masters?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchMasters'
        });
    }

    storeBrand(body) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${API_URL}/api/brands`, body);
            },
            onSuccess: async (response) => response.data,
            onError: async (error) => error,
            actionType: 'storeBrand'
        });
    }

    duplicateBrand(brandId, body) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${API_URL}/api/brands/${brandId}/copy`, body);
            },
            onSuccess: async (response) => response.data,
            onError: async (error) => error,
            actionType: 'duplicateBrand'
        });
    }

    applyFolderToBrand(brandId, folderId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${API_URL}/api/brands/${brandId}/attach`, { folder_id: folderId });
            },
            onSuccess: async (response) => response.data,
            actionType: 'applyFolderToBrand'
        });
    }

    copyBrandToOrganization(brandId, organizationId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${API_URL}/api/brands/${brandId}/move`, {
                    organization_id: organizationId
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'copyBrandToOrganization'
        });
    }

    deleteBrand(brandId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.delete(`${API_URL}/api/brands/${brandId}`);
            },
            onSuccess: async (response) => response.data,
            onError: async (error) => error,
            actionType: 'deleteBrand'
        });
    }
}