<template>
    <div class="copy-video-modal">
        <ui-modal-header :title="$t('modals.translateModal.title')" />

        <dropdown v-model="translateOptionId" class="basic-dropdown">
            <template #dropdown-selected-value>
                <span v-if="selectedTranslateOption">
                    {{ selectedTranslateOption.label }}
                </span>

                <span v-else class="placeholder">
                    {{ $t('modals.translateModal.selectOption') }}
                </span>
            </template>
            <ui-infinite-scroll-paginator :loading="templateOptionsIsLoading">
                <dropdown-item
                    v-for="translateOption in translateOptions"
                    :key="translateOption.id"
                    :value="translateOption.id"
                >
                    <span class="dropdown-list-item-label">{{ translateOption.label }}</span>
                </dropdown-item>
                <template v-slot:loading>
                    <LoadingMarker v-if="templateOptionsIsLoading" />
                </template>
            </ui-infinite-scroll-paginator>
        </dropdown>

        <button
            :disabled="!selectedTranslateOption || requestSent"
            class="ui-simple-button ui-simple-button-select modal-form-confirm-button"
            @click="translate()"
        >
            {{ $t('actions.translate') }}
        </button>
    </div>
</template>

<script setup lang="ts">
import '@/sass/ui/modals/copy-video-modal.scss';

// @ts-ignore
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
// @ts-ignore
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
// @ts-ignore
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
// @ts-ignore
import LoadingMarker from '@/js/components/LoadingMarker.vue';
// @ts-ignore
import UiModalHeader from '@/js/components/modals/UiModalHeader.vue';

import { computed, onMounted, ref, watch } from 'vue';
// @ts-ignore
import { useTranslateOptions } from '@/js/composables/useTranslateOptions.js';
// @ts-ignore
import { useModal } from '@/js/composables/useModal.js';
// @ts-ignore
import TranslateApiService from '@/js/infrastructure/api/translate/TranslateApiService.js';

interface TranslateOption {
    id: string;
    label: string;
}

const { closeModal, modalData } = useModal();
const { templateOptionsIsLoading, translateOptions, fetchTranslateOptions } = useTranslateOptions();

const translateOptionId = ref<string | null>(null);
const selectedTranslateOption = ref<TranslateOption | null>(null);

const elements = computed(() => modalData.value.elements);
const type = computed(() => modalData.value.type);
const brandId = computed(() => modalData.value.brandId) ?? null;

const requestSent = ref(false);

const selectTranslateOption = (optionId: string | null) => {
    selectedTranslateOption.value = optionId
        ? translateOptions.value.find((option: TranslateOption) => option.id === optionId) || null
        : null;
};

watch(translateOptionId, (newValue) => {
    selectTranslateOption(newValue);
});

const translate = () => {
    if (translateOptionId.value === null) return;

    requestSent.value = true;
    new TranslateApiService()
        .translateBatchedElements(type.value, elements.value, translateOptionId.value, brandId.value ?? null)
        .then(() => {
            closeModal();
        });
};

onMounted(() => {
    fetchTranslateOptions();
});
</script>
