<template>
    <ui-color-selector
        v-if="backgroundType === COLOR_TYPE"
        :id="'card-seq-' + seqId + '-bg-color'"
        key="color-selector-bg-color"
        v-model:color="backgroundColor"
        :default-color="backgroundDefaultColor"
        :palette="brandPalette"
        :enable-other-colors="brandEnableOtherColors"
        :label="$t('studio.background_types.color')"
        :showLabel="false"
        :disabled="readOnly || hasPreviousBackground"
    />
    <ui-media-selector
        v-else-if="isMediaSelectorType"
        :id="'card-seq-' + seqId + '-bg-' + backgroundMediaType"
        :key="'media-selector-bg-' + backgroundMediaType"
        v-model:media="backgroundMedia"
        :type="backgroundMediaType"
        :label="$t('studio.background_types.' + backgroundMediaType)"
        :showLabel="false"
        :disabled="readOnly || hasPreviousBackground"
        :icon="mediaSelectorIcon"
        :trigger-opening="triggerSelection && isMediaSelectorType && !libraryId"
        @[mediaSelectorShowEvent]="openMediaLibrary"
        @[mediaSelectorChangeEvent]="handleMediaSelectorChange"
    />
    <ui-library-selector
        v-else-if="backgroundType === COLLAGE_TYPE"
        :id="'card-seq-' + seqId + '-bg-animation-collage'"
        key="library-selector-bg-animation-collage"
        v-model="backgroundAnimationType"
        :default-value="backgroundCollageDefaultAnimationType"
        library="backgroundAnimations"
        category="background-collage"
        preview-path="animations/background/"
        :label="$t('Collage')"
        :showLabel="false"
        :disabled="readOnly || hasPreviousBackground"
        icon="fa-regular fa-table-layout"
        :trigger-opening="triggerSelection && backgroundType === COLLAGE_TYPE && !libraryId"
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationType"
    />
    <ui-location-selector
        v-if="backgroundType === MAP_ZOOM_TYPE"
        :id="'card-seq-' + seqId + '-bg-map-zoom'"
        key="color-selector-bg-map-zoom"
        v-model:location="backgroundMapZoom"
        :label="$t('studio.background_types.mapZoom')"
        :showLabel="false"
        :disabled="readOnly || hasPreviousBackground"
    />
    <ui-recording-selector
        v-else-if="backgroundType === RECORDING_TYPE"
        :id="'card-seq-' + seqId + '-bg-recording'"
        :key="'recording-selector-bg-recording'"
        v-model:recording="backgroundRecording"
        :seqId="seqId"
        :type="RECORDING_TYPE_SCREEN"
        :excluded-types="[RECORDING_TYPE_MICROPHONE]"
        :label="$t('studio.background_types.' + backgroundType)"
        :showLabel="false"
        :disabled="readOnly || hasPreviousBackground"
        :trigger-opening="triggerSelection && backgroundType === RECORDING_TYPE && !libraryId"
        @[recordingSelectorChangeEvent]="resetVideoCaptions"
    />
</template>

<script setup>
import { computed, watch, inject } from 'vue';
import { useStore } from 'vuex';

import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '@/js/components/UiRecordingSelector.vue';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '@/js/components/UiLibrarySelector.vue';
import UiLocationSelector from '@/js/videos/components/ui/body/UiLocationSelector.vue';
import UiMediaSelector, {
    UI_MEDIA_TYPE_ANIMATED,
    UI_MEDIA_TYPE_CARTOON,
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_TYPE_VIDEO,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_SELECTOR_CHANGE
} from '@/js/components/UiMediaSelector.vue';
import { Background, Color } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { RECORDING_TYPE_MICROPHONE, RECORDING_TYPE_SCREEN } from '@/js/constants/index.js';
import { STAGE_LOADING_ENDED_EVENT } from '@/js/video-studio/utils/index.js';
import { getPrefixedUuid } from '@/js/utils.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String
});

const store = useStore();
const { libraryTagOrders, brandPalette, brandEnableOtherColors } = useBrand();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const { readOnly } = useVideo();
const {
    sequenceState,
    sequenceStoreModulePath,
    resolvedBackgroundState,
    hasMapZoomBackground,
    hasPreviousBackground,
    backgroundType
} = useSequence(props.seqId);

const videoStudio = inject('$videoStudio');

const libraryId = computed(() => store.state.ui.currentLibrary.id);
const mediaSrc = computed(() => store.getters['ui/quickcut/getMediaSrc']);
const isMediaSelectorType = computed(() =>
    [Background.IMAGE_TYPE, Background.VIDEO_TYPE, Background.CARTOON_TYPE, Background.ANIMATED_TYPE].includes(
        backgroundType.value
    )
);

const prefixes = computed(() => store.state.ui.prefixes);

const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const recordingSelectorChangeEvent = UI_RECORDING_SELECTOR_CHANGE;

const COLOR_TYPE = Background.COLOR_TYPE;
const COLLAGE_TYPE = Background.COLLAGE_TYPE;
const MAP_ZOOM_TYPE = Background.MAP_ZOOM_TYPE;
const RECORDING_TYPE = Background.RECORDING_TYPE;
const backgroundDefaultColor = Color.BACKGROUND_DEFAULT;
const backgroundCollageDefaultAnimationType = Background.COLLAGE_DEFAULT_TYPE;

// must be false before mounted
let triggerSelection = false;
watch(backgroundType, () => (triggerSelection = true));

const backgroundImage = computed({
    get: () => {
        let ref = { src: '', id: '' };

        if ([Background.IMAGE_TYPE, Background.CARTOON_TYPE].includes(backgroundType.value)) {
            ref.src = resolvedBackgroundState.value.image.src;
            ref.id = resolvedBackgroundState.value.image.src__id;
        }

        return ref;
    },
    set: ({ src, id }) => {
        saveHistoryStep(() => {
            if (!sequenceState.value.background.image.src__ref) {
                store.commit(
                    `${sequenceStoreModulePath.value}/image/setSourceRef`,
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(`${sequenceStoreModulePath.value}/image/update`, { src, src__id: id });
        });
    }
});

const backgroundVideo = computed({
    get: () => {
        let ref = { src: '', id: '' };

        if ([Background.VIDEO_TYPE, Background.ANIMATED_TYPE].includes(backgroundType.value)) {
            ref.src = resolvedBackgroundState.value.video.src;
            ref.id = resolvedBackgroundState.value.video.src__id;
        }

        return ref;
    },
    set: ({ src, id }) => {
        // reset video timerange segments and timerange if the video source is changed for UiQuickCut
        if (mediaSrc !== src) {
            store.commit(`${sequenceStoreModulePath.value}/video/setTimerangeSegments`, []);
            store.commit(`${sequenceStoreModulePath.value}/video/setTimerange`, { start: 0, end: 0 });
        }

        saveHistoryStep(() => {
            if (!sequenceState.value.background.video.src__ref) {
                store.commit(
                    `${sequenceStoreModulePath.value}/video/setSourceRef`,
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(`${sequenceStoreModulePath.value}/video/update`, { src, src__id: id });
        });
    }
});

const backgroundColor = computed({
    get: () => {
        let color = { ref: Color.BACKGROUND_DEFAULT, value: Color.BACKGROUND_DEFAULT };

        if (backgroundType.value === Background.COLOR_TYPE) {
            color.ref = resolvedBackgroundState.value.color.start__ref;
            color.value = resolvedBackgroundState.value.color.start;
        }

        return color;
    },
    set: ({ ref, value }) => {
        saveHistoryStep(() => {
            store.commit(`${sequenceStoreModulePath.value}/setBackgroundColor`, {
                start__ref: ref,
                start: value
            });
        });
    }
});

const backgroundMedia = computed({
    get: () => {
        switch (backgroundType.value) {
            case Background.IMAGE_TYPE:
            case Background.CARTOON_TYPE:
                return backgroundImage.value;
            case Background.VIDEO_TYPE:
            case Background.ANIMATED_TYPE:
                return backgroundVideo.value;
            default:
                return {};
        }
    },
    set: (media) => {
        store.commit('edition/setEditingElement', null);
        store.dispatch(`${sequenceStoreModulePath.value}/resetPosition`); // reset background position when media changes

        switch (backgroundType.value) {
            case Background.IMAGE_TYPE:
            case Background.CARTOON_TYPE:
                backgroundImage.value = media;
                break;
            case Background.VIDEO_TYPE:
            case Background.ANIMATED_TYPE:
                backgroundVideo.value = media;
                break;
        }
    }
});

const backgroundMediaType = computed(() => {
    switch (backgroundType.value) {
        case Background.IMAGE_TYPE:
            return UI_MEDIA_TYPE_IMAGE;
        case Background.VIDEO_TYPE:
            return UI_MEDIA_TYPE_VIDEO;
        case Background.CARTOON_TYPE:
            return UI_MEDIA_TYPE_CARTOON;
        case Background.ANIMATED_TYPE:
            return UI_MEDIA_TYPE_ANIMATED;
        default:
            return '';
    }
});

const mediaSelectorIcon = computed(() => {
    switch (backgroundType.value) {
        case Background.IMAGE_TYPE:
            return 'fa-regular fa-image';
        case Background.CARTOON_TYPE:
            return 'fa-regular fa-house-tree';
        case Background.VIDEO_TYPE:
            return 'fa-regular fa-circle-play';
        case Background.ANIMATED_TYPE:
            return 'fa-regular fa-square-bolt';
        default:
            return '';
    }
});

const backgroundMapZoom = computed({
    get: () => {
        let location = { coords: Background.DEFAULT_MAP_ZOOM_COORDS, address: '' };

        if (hasMapZoomBackground.value) {
            location.coords = resolvedBackgroundState.value.mapZoom.coords;
            location.address = resolvedBackgroundState.value.mapZoom.address;
        }

        return location;
    },
    set: ({ coords, address }) => {
        saveHistoryStep(() => {
            store.dispatch(`${sequenceStoreModulePath.value}/updateBackgroundMapZoomLocation`, { coords, address });
        });
    }
});

const backgroundAnimationType = computed({
    get: () =>
        (backgroundType.value === Background.IMAGE_TYPE ||
            backgroundType.value === Background.VIDEO_TYPE ||
            backgroundType.value === Background.ANIMATED_TYPE ||
            backgroundType.value === Background.COLLAGE_TYPE ||
            backgroundType.value === Background.CARTOON_TYPE ||
            backgroundType.value === Background.RECORDING_TYPE) &&
        resolvedBackgroundState.value.animation.type,
    set: (type) => {
        saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setBackgroundAnimation`, type));
    }
});

const backgroundRecording = computed({
    get: () => {
        let ref = { src: '', id: '' };

        if (backgroundType.value === Background.RECORDING_TYPE) {
            ref.src = resolvedBackgroundState.value.video.src;
            ref.id = resolvedBackgroundState.value.video.src__id;
        }

        return ref;
    },
    set({ src, id }) {
        saveHistoryStep(() => {
            if (!sequenceState.value.background.video.src__ref) {
                store.commit(
                    `${sequenceStoreModulePath.value}/video/setSourceRef`,
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(`${sequenceStoreModulePath.value}/video/update`, { src, src__id: id });
        });
    }
});

const openLibrary = (selector) => {
    const isMusicLibrary = selector.library === 'musics';

    store.commit('ui/setCurrentLibrary', {
        id: selector.library,
        category: selector.category,
        tagOrder: selector.tagOrder || libraryTagOrders[selector.library] || null,
        selectedItemId: isMusicLibrary ? selector.modelValue.id : selector.modelValue,
        selector
    });
};

const openMediaLibrary = (selector) => {
    store.commit('ui/setCurrentMediaLibrary', {
        mediaType: selector.type,
        selectedMediaId: selector.media.id,
        selector
    });
};

const previewBackgroundAnimationType = (type, cancel) => {
    ignoreHistoryStep(() => {
        store.commit(`${sequenceStoreModulePath.value}/setBackgroundAnimation`, type);
        if (!cancel)
            videoStudio.value.studio.$stage.$el.addEventListener(
                STAGE_LOADING_ENDED_EVENT,
                playBackgroundAnimationTypePreview,
                { once: true }
            );
    });
};

const playBackgroundAnimationTypePreview = () => {
    store.commit('preview/setCurrentTimelineId', props.seqId);
    videoStudio.value.studio.$stage.seekSequenceTimeline(props.seqId, 0.001);
    videoStudio.value.studio.$stage.playTimeline();
};

const handleMediaSelectorChange = () => {
    if (backgroundMediaType.value === Background.VIDEO_TYPE) resetVideoCaptions();
};

const resetVideoCaptions = () => {
    saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/video/setCaptions`, false));
};
</script>
