<template>
    <dropdown v-model="selectedClient" class="basic-dropdown">
        <template #dropdown-selected-value>
            <span v-if="selectedClient">{{ selectedClient.name }}</span>
        </template>
        <UiTextInput
            id="client-selector-search"
            type="search"
            :placeholder="$t('Search')"
            :show-label="false"
            icon="search-icon"
            v-model="searchFilter"
        />
        <ui-infinite-scroll-paginator v-model="page" :max-page="totalPages" :loading="isLoading">
            <dropdown-item v-for="client in clients" :key="client.id" :value="client">
                <span class="dropdown-list-item-label">{{ client.name }}</span>
            </dropdown-item>
            <template v-slot:loading>
                <LoadingMarker v-if="isLoading" />
            </template>
        </ui-infinite-scroll-paginator>
    </dropdown>
    <TextError :errors="props.errors" />
</template>

<script setup lang="ts">
import { computed, defineModel, onMounted, ref, watch } from 'vue';
// @ts-ignore
import UiTextInput from '@/js/components/UiTextInput.vue';
// @ts-ignore
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
// @ts-ignore
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
// @ts-ignore
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
// @ts-ignore
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import { debounce } from 'lodash';
// @ts-ignore
import { useClients } from '@/js/composables/useClients.js';
// @ts-ignore
import { useAuth } from '@/js/composables/useAuth.js';

import TextError from '@/js/components/TextError.vue';

interface Client {
    id: number | string;
    name: string;
    shortname: string;
}

const selectedClient = defineModel<Client | null>();

const { page, totalPages, isLoading, clients, fetchClients, resetDataForClients } = useClients();
const { user } = useAuth();

const searchFilter = ref('');

const props = defineProps({
    errors: {
        type: Array as () => string[],
        default: () => []
    },
    storageKey: {
        type: String,
        default: 'selectedClientId'
    }
});

const STORAGE_KEY = computed(() => `${user.value?.id || 'user'}-${props.storageKey}`);

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

// Save selected client to localStorage when it changes
watch(selectedClient, (client) => {
    if (client && client.id) {
        localStorage.setItem(STORAGE_KEY.value, String(client.id));
    } else {
        localStorage.removeItem(STORAGE_KEY.value);
    }
});

// Restore selected client from localStorage
const restoreSelectedClient = () => {
    if (!clients.value || clients.value.length === 0) return;

    const savedClientId = localStorage.getItem(STORAGE_KEY.value);
    if (savedClientId) {
        const foundClient = clients.value.find((client: Client) => String(client.id) === savedClientId);
        if (foundClient && !selectedClient.value) {
            selectedClient.value = foundClient;
            return;
        }
    }

    // If no client is selected and we couldn't restore from localStorage, select the first one
    if (!selectedClient.value && clients.value.length > 0) {
        selectedClient.value = clients.value[0] as Client;
    }
};

watch(
    clients,
    () => {
        restoreSelectedClient();
    },
    { immediate: true }
);

const debouncedSearch = debounce(() => {
    resetDataForClients();
    fetchClients(options.value);
}, 500);

watch(searchFilter, () => {
    debouncedSearch();
});

watch(page, () => {
    fetchClients(options.value);
});

onMounted(() => {
    fetchClients(options.value);
});
</script>
